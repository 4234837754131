@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('typeface-nixie-one');
@font-face {
  font-family: 'Bebas';
  src: url('./bebas.ttf') format('truetype');
}

@font-face {
  font-family: 'Bunge';
  src: url('./BungeeHairline-Regular.ttf') format('truetype');
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Raleway'
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


body, h1, h2, h3, h4, h5, p, header {
  font-family: 'Arial';
}

body{
  margin-top: 96px;
}

/* BodyJtd {
  margin-top: 96px;
  max-width: 1400px; 
}
 */
